// src/components/FAQAccordion.js

import React, { useState } from "react";
import "../styles.css";

const faqData = [
  {
    question: "What is AppliMatch?",
    answer:
      "AppliMatch is a platform that helps you find your dream job by generating tailored resumes and cover letters, and giving you insights into market value and job match scores.",
  },
  {
    question: "How does AppliMatch customize my application materials?",
    answer:
      "We use advanced AI algorithms to tailor your resumes and cover letters to specific job descriptions, highlighting the most relevant skills and experiences.",
  },
  {
    question: "Is AppliMatch free to use?",
    answer:
      "We offer free credits for new users with access to basic features. Premium features are available with our paid packages.",
  },
  {
    question: "Does AppliMatch support all jobs?",
    answer:
      "We support most of the famous ATS platforms. We are working on expanding our support to more platforms. Please let us at support@applimatch.co know if you face any issues adding a job.",
  },
  {
    question: "When will AppliMatch be fully launched?",
    answer:
      "We have launched our beta for users to try. Sign up now to be the first to know when we launch the full product!",
  },
];

const FAQAccordion = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleFAQ = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
  };

  return (
    <section className="faq-section">
      <h2>Frequently Asked Questions</h2>
      <div className="accordion-container">
        {faqData.map((item, index) => (
          <div
            className={`accordion-item ${
              activeIndex === index ? "active" : ""
            }`}
            key={index}
          >
            <div
              className="accordion-question"
              onClick={() => toggleFAQ(index)}
            >
              {item.question}
              <span className="accordion-icon">
                {activeIndex === index ? "-" : "+"}
              </span>
            </div>
            <div
              className="accordion-answer"
              style={{
                maxHeight: activeIndex === index ? "200px" : "0px",
              }}
            >
              <p>{item.answer}</p>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default FAQAccordion;
